<template>
    <header class="header">
        <div class="header__wrapper">
            <app-logo />
            <div class="halal">
                <img :src="require(`@/assets/images/halalRSK3l.png`)" alt="halal" />
            </div>
            <router-link to="/nikahi" class="link-page">Никахи</router-link>
            <div class="mobile-tel">
                <a href="tel:+79273546263" onclick="ym(95816218,'reachGoal','phone'); return true;"
                    ><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.93012 3.33659L3.63404 0.569285C2.81874 0.0809365 0.996883 -0.342299 0.231849 1.87155C-0.533185 4.08539 0.777134 6.70077 1.52792 7.73173C2.932 9.63086 6.58261 13.5593 9.9524 14.0803C14.1646 14.7314 14.8127 11.9641 14.9747 11.3129C15.1043 10.792 14.7047 10.3363 14.4887 10.1735C13.8946 9.90216 12.577 9.29444 12.0585 9.03399C11.5401 8.77354 10.9785 9.14251 10.7625 9.35955C10.7084 9.46808 10.4708 9.8479 9.9524 10.499C9.30437 11.3129 8.98035 10.8246 7.68428 10.1735C6.3882 9.52234 5.25414 8.22007 4.12007 6.91781C2.98601 5.61555 3.95806 5.45277 4.6061 4.80164C5.12453 4.28073 5.03812 3.6079 4.93012 3.33659Z"
                            fill="#fff"
                        />
                    </svg>
                </a>
            </div>
            <div class="header__body">
                <ul class="header__menu" :style="$route.path === '/account' ? 'visibility: hidden' : false">
                    <li><router-link to="/reservation">Забронировать</router-link></li>
                    <li><a href="/#photos">Фото</a></li>
                    <li><a href="/#contacts">Контакты</a></li>
                    <li><a href="#" @click="openPopupFeedback">Оставить отзыв</a></li>
                    <li><router-link to="/nikahi">Никахи</router-link></li>
                </ul>
                <div class="header__login">
                    <a
                        href="tel:+79273546263"
                        class="header__tel"
                        onclick="ym(95816218,'reachGoal','phone'); return true;"
                        >+7 927 354 62 63</a
                    >
                    <button v-if="login === 'Войти'" class="header__account" @click="popupLogin = true">
                        <span>Войти</span>
                        <img src="../assets/images/svg/account.svg" alt="account" />
                    </button>
                    <router-link to="/account" v-else class="header__account">
                        <span>{{ login }}</span>
                        <img src="../assets/images/svg/account.svg" alt="account" />
                    </router-link>
                </div>
            </div>
            <nav class="nav" v-if="$route.path !== '/account'">
                <button @click="toggleMenu" class="nav__burger burger">
                    <span class="burger__line burger__line--1"></span>
                    <span class="burger__line burger__line--2"></span>
                    <span class="burger__line burger__line--3"></span>
                </button>
                <div class="nav__wrapper" :class="{ 'nav__wrapper--open': isVisible }">
                    <div class="nav__header">
                        <button @click="toggleMenu" class="nav__burger">
                            <svg
                                width="19"
                                height="13"
                                viewBox="0 0 19 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M18 6.5L2 6.5" stroke="#373737" stroke-width="1.5" stroke-linecap="round" />
                                <path
                                    d="M6 11.5L1.70711 7.20711C1.31658 6.81658 1.31658 6.18342 1.70711 5.79289L6 1.5"
                                    stroke="#373737"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                            </svg>
                        </button>
                        <a href="tel:+79273546263" class="nav__phone nav__link">+7 927 354 62 63</a>
                    </div>
                    <ul class="nav__menu menu">
                        <li class="menu__item">
                            <RouterLink to="/reservation" class="nav__link">Забронировать</RouterLink>
                        </li>
                        <li class="menu__item">
                            <a href="/#photos" class="nav__link">Фото ресторана</a>
                        </li>
                        <li class="menu__item">
                            <a href="/#contacts" class="nav__link">Контакты</a>
                        </li>
                        <li class="menu__item">
                            <a href="#" class="nav__link" @click="openPopupFeedback">Оставить отзыв</a>
                        </li>
                    </ul>
                    <div class="menu__login menu">
                        <a
                            href="tel:+79273546263"
                            class="menu__tel"
                            onclick="ym(95816218,'reachGoal','phone'); return true;"
                            >+7 927 354 62 63</a
                        >
                        <button v-if="login === 'Войти'" class="menu__account" @click="popupLogin = true">
                            <span>Войти</span>
                            <img src="../assets/images/svg/account.svg" alt="account" />
                        </button>
                        <router-link to="/account" v-else class="menu__account">
                            <span>{{ login }}</span>
                            <img src="../assets/images/svg/account.svg" alt="account" />
                        </router-link>
                    </div>
                </div>
            </nav>
        </div>
    </header>
    <login-popup v-if="popupLogin" @click="popupLogin = !popupLogin" @close="hidePopup" @auth="userAuth" />
    <certif-popup v-if="popupCertif" @click="popupCertif = !popupCertif" />
    <feedback-popup
        v-if="isPopupFeedback"
        @click="isPopupFeedback = false"
        @close-popup-feedback="closePopupFeedback"
    />
</template>

<script>
import AppLogo from "./AppLogo.vue";
import CertifPopup from "./CertifPopup.vue";
import LoginPopup from "./LoginPopup.vue";
import axios from "axios";
import FeedbackPopup from "./FeedbackPopup.vue";

export default {
    components: { AppLogo, LoginPopup, CertifPopup, FeedbackPopup },
    name: "app-header",
    created() {},
    data() {
        return {
            popupLogin: false,
            popupCertif: false,
            isVisible: false,
            isPopupFeedback: false,
        };
    },
    props: {
        login: {
            type: String,
            default: "Войти",
        },
    },
    emits: {
        authData: null,
    },
    methods: {
        openPopupFeedback() {
            this.isPopupFeedback = true;
        },
        closePopupFeedback() {
            this.isPopupFeedback = false;
        },
        hidePopup() {
            this.popupLogin = false;
        },
        toggleMenu() {
            this.isVisible = !this.isVisible;
        },
        async userAuth(token) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/projects/zaman/profile/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                });
                console.log(response.data);
                localStorage.setItem("token", token);
                localStorage.setItem("username", response.data.username);
                localStorage.setItem("name", response.data.first_name);
                this.$emit("authData", response.data);
                this.popupLogin = false;
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },
    },
};
</script>

<style lang="scss">
.header {
    background-color: #2a374b;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    .mobile-tel {
        display: none;
        a {
            font-family: "Nunito", sans-serif;
            color: #fff;
            line-height: 20px;
            font-size: 14px;
            text-decoration: none;
        }
    }
    @media (max-width: 767px) {
        height: auto;
        padding: 0 1rem;
        .mobile-tel {
            display: block;
        }
    }
    &__body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1 1 auto;
        @media (max-width: 767px) {
            display: none;
        }
        @media (max-width: 360px) {
            justify-content: flex-end;
        }
    }
    &__menu {
        display: flex;
        @media (max-width: 1120px) {
            flex-wrap: wrap;
        }
        @media (max-width: 360px) {
            display: none;
        }
        li {
            list-style: none;
            margin-left: 2.3rem;
            @media (max-width: 1120px) {
                margin-left: 1rem;
                padding: 5px 0;
            }
        }
        a {
            font-family: "Nunito Sans", sans-serif;
            color: #fff;
            line-height: clamp(8px, 5vw, 27.28px);
            font-size: clamp(8px, 5vw, 20px);
            text-decoration: none;
            &:hover,
            &:focus {
                color: #ccc;
                transition: 0.2s ease 0.2s;
            }
        }
    }
    &__login {
        display: flex;
        align-items: center;
        @media (max-width: 991px) {
            margin-top: 1rem;
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
        @media (max-width: 767px) {
            display: none;
        }
    }
    &__account {
        font-family: "Nunito Sans", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 27.28px;
        text-align: center;
        color: #fff;
        margin-left: 3rem;
        display: flex;
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        &:hover,
        &:focus {
            color: #ccc;
            text-shadow: 2px 2px 10px #000;
            transition: 0.2s ease 0.2s;
        }
        img {
            margin-left: 5px;
        }
    }
    &__tel {
        font-family: "Nunito", sans-serif;
        color: #fff;
        line-height: clamp(8px, 5vw, 25.2px);
        font-size: clamp(8px, 5vw, 18px);
        text-decoration: none;
        flex: 0 0 auto;
        &:hover,
        &:focus {
            color: #ccc;
            transition: 0.2s ease 0.2s;
        }
        @media (max-width: 767px) {
            display: none;
        }
    }
    /* .header__wrapper */
    .header__wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
}
.burger {
    flex-direction: column;
    align-items: end;
    row-gap: 7px;
}
.burger__line {
    width: 23px;
    height: 1px;
    background-color: #fff;
    border-radius: 5px;
}
.burger__line--2 {
    width: 11px;
}
.burger__line--3 {
    width: 19px;
}
.halal {
    width: 70px;
    height: 70px;
    margin-left: 2rem;
    @media (max-width: 767px) {
        width: 30px;
        height: 30px;
        margin-left: 0;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.nav {
    display: none;
}
.nav__header {
    height: 48px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav__burger {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
}
.nav__link {
    text-decoration: none;
    color: #373737;
}
.nav__wrapper {
    display: none;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 360px;
    height: 436px;
}
.nav__wrapper--open {
    display: flex;
    top: 50px;
    z-index: 3;
}
.menu {
    list-style-type: none;
    padding: 0 16px;
    border-top: 1px solid #373737;
    background: #fff;
    z-index: 3;
}
.menu__item {
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
}
.menu {
    &__tel {
        height: 40px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;
        text-decoration: none;
        color: #373737;
    }
    &__account {
        display: flex;
        align-items: center;
        margin: 0 auto;
        border: none;
        background: none;
        color: #373737;
    }
}
.link-page {
    display: none;
    color: #fff;
    text-decoration: none;
}
@media (max-width: 767px) {
    .nav,
    .link-page {
        display: block;
    }
    .nav__phone {
        display: none;
    }
}
</style>
