<template>
    <main>
        <main-page-banner />
        <main-set-menu />
        <main-page-stories />
        <main-page-catalog />
        <main-page-reservation />
        <main-banquet-hall />
        <main-page-photos />
    </main>
</template>

<script>
import MainPageBanner from "../components/MainPageBanner.vue";
import MainPageCatalog from "../components/MainPageCatalog.vue";
import MainPagePhotos from "../components/MainPagePhotos.vue";
import MainPageReservation from "../components/MainPageReservation.vue";
import MainPageStories from "../components/MainPageStories.vue";
import MainBanquetHall from "@/components/MainBanquetHall.vue";
import MainSetMenu from "@/components/MainSetMenu.vue";
export default {
    components: {
        MainPageBanner,
        MainPageStories,
        MainPageCatalog,
        MainPageReservation,
        MainPagePhotos,
        MainBanquetHall,
        MainSetMenu,
    },
    name: "main-zaman",
};
</script>
