<template>
    <section class="set-menu">
        <div class="center-wrapper">
            <div class="set-menu__container">
                <div class="set-menu__blocks">
                    <div class="set-menu__block desktop" v-for="image in images" :key="image.id">
                        <img :src="require(`@/assets/images/set-menu/${image.img}`)" :alt="image.img" />
                        <div class="set-menu__body" v-if="image.id === Math.round(images.length / 2)">
                            <h3 class="set-menu__title">{{ title }}</h3>
                            <h4 class="set-menu__subtitle">{{ subtitle }}</h4>
                            <div class="set-menu__text">
                                {{ text }}
                            </div>
                            <button class="set-menu__btn" @click="routerHrefLink()">
                                <span>Смотреть меню</span
                                ><svg
                                    width="13"
                                    height="22"
                                    viewBox="0 0 13 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M1 1L11 11L1 21" stroke="#fff" stroke-width="2" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="set-menu__block mobile">
                        <img :src="require(`@/assets/images/set-menu/${img_m}`)" :alt="img_m" />
                        <div class="set-menu__body">
                            <h3 class="set-menu__title">{{ title }}</h3>
                            <h4 class="set-menu__subtitle">{{ subtitle }}</h4>
                            <div class="set-menu__text">
                                {{ text_m }}
                            </div>
                            <button class="set-menu__btn" @click="routerHrefLink()">
                                <span>Смотреть меню</span
                                ><svg
                                    width="13"
                                    height="23"
                                    viewBox="0 0 13 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1.94824L11 11.8923L1 21.8364"
                                        stroke="#fff"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "MainSetMenu",
    data() {
        return {
            images: [
                { id: 1, img: "1.png" },
                { id: 2, img: "2.png" },
                { id: 3, img: "3.png" },
            ],
            title: "Сет-меню",
            subtitle: "Характеры осени",
            text: `Осень – время, когда продукты
                раскрывают свою индивидуальность.
                Мясо уток и гусей к осени приобретает
                особую сочность. Выращенные татарскими
                фермерами сезонные яблоки, тыква
                и кукуруза разных сортов делают палитру
                вкусов яркой и разнообразной.
                Какой характер будет у вашей осени?`,
            img_m: "mobile.png",
            text_m: `Осень – время, когда продукты раскрывают свою индивидуальность. Мясо уток и гусей к осени приобретает особую сочность. Выращенные татарскими фермерами сезонные яблоки, тыква и кукуруза разных сортов делают палитру вкусов яркой и разнообразной`,
            pdf: "menu.pdf",
        };
    },
    methods: {
        routerHrefLink() {
            window.open(this.pdf, "_blank");
        },
    },
};
</script>

<style lang="scss">
@font-face {
    font-family: "Vetrino";
    src: url("~@/assets/fonts/VetrinoRegular.woff2") format("woff2"),
        url("~@/assets/fonts/VetrinoRegular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.set-menu {
    background-color: #f6ece9;
    padding-top: 50px;
    padding-bottom: 70px;
    @media (max-width: 767px) {
        padding-bottom: 50px;
    }
    .mobile {
        display: none;
        margin: 0 auto;
        @media (max-width: 767px) {
            display: block;
        }
    }
    .desktop {
        @media (max-width: 767px) {
            display: none;
        }
    }

    &__blocks {
        display: flex;
        margin: 0 -10px;
        @media (max-width: 767px) {
            margin: 0 auto;
        }
    }

    &__block {
        position: relative;
        flex: 0 0 33.33%;
        padding: 0 10px;
        overflow: hidden;
        border-radius: 45% 45% 0 0;
        @media (max-width: 767px) {
            flex: 1 1 auto;
            padding: 0;
            border-radius: 500px 500px 0 0;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__body {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        color: #fff;
        text-align: center;
        padding: 100px 50px 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 1440px) {
            padding: 20px;
        }
        @media (max-width: 992px) {
        }
        @media (max-width: 767px) {
            height: 100%;
            padding: 30px;
            justify-content: flex-start;
            flex-direction: column;
        }
    }

    &__title {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        @media (max-width: 1440px) {
            font-size: 18px;
        }
        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 20px;
        }
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 24px;
            color: #5a5a5a;
        }
    }

    &__subtitle {
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        font-family: "Vetrino";
        margin-top: 10px;
        @media (max-width: 1440px) {
            font-size: 24px;
        }
        @media (max-width: 1200px) {
            margin-top: 0;
        }
        @media (max-width: 992px) {
            font-size: 18px;
            line-height: 20px;
            margin-top: 0;
        }
        @media (max-width: 767px) {
            font-size: 24px;
            line-height: 24px;
            color: #2a374b;
            margin-top: 10px;
        }
    }

    &__text {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        margin-top: 40px;
        white-space: pre-line;
        @media (max-width: 1440px) {
            margin-top: 20px;
            white-space: unset;
        }
        @media (max-width: 1200px) {
            margin-top: 10px;
            font-size: 12px;
        }
        @media (max-width: 992px) {
            font-size: 10px;
            line-height: 15px;
            margin-top: 5px;
        }
        @media (max-width: 767px) {
            color: #0d0d0d;
            margin-top: 20px;
            flex: 1 1 auto;
            padding: 0 100px;
        }
        @media (max-width: 480px) {
            padding: 0 20px;
        }
        @media (max-width: 400px) {
            padding: 0;
        }
    }

    &__btn {
        width: 100%;
        max-width: 336px;
        height: 72px;
        border-radius: 8px;
        border: 1.5px solid #fff;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        display: flex;
        align-items: center;
        color: #fff;
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
        margin: 50px auto 0;
        background: none;
        @media (max-width: 1440px) {
            margin: 20px auto 0;
            font-size: 18px;
        }
        @media (max-width: 1200px) {
            height: 50px;
            font-size: 14px;
            margin: 10px auto 0;
        }
        @media (max-width: 992px) {
            height: auto;
            padding: 0 10px;
            margin: 5px auto 0;
        }
        @media (max-width: 767px) {
            height: 56px;
            font-size: 16px;
            line-height: 28px;
            max-width: 100%;
            padding: 0 20px;
        }
        &:hover,
        &:focus {
            background: #fff;
            color: #000;
            transition: 0.2s ease 0.2s;
            svg {
                path {
                    stroke: #000;
                    transition: 0.2s ease 0.2s;
                }
            }
        }
    }
}
</style>
