<template>
    <div class="banquet-hall">
        <div class="banquet-hall__container center-wrapper">
            <div class="banquet-hall__body">
                <div class="banquet-hall__text">
                    <h2 class="banquet-hall__title">
                        {{ title }}
                    </h2>
                    <ul class="banquet-hall__list">
                        <li>- Национальная кухня с сертификатом «Халяль»</li>
                        <li>- Открытая терраса на крыше с панорамным видом</li>
                        <li>- Молельная комната</li>
                        <li>- Шоу с чак-чаком, шоу с гусем, кумыс фуршет</li>
                    </ul>
                </div>
                <div class="banquet-hall__form">
                    <form @submit.prevent>
                        <div class="input-block">
                            <label for="name">Имя</label>
                            <input type="text" name="name" placeholder="Ваше имя" id="name" v-model.trim="name" />
                        </div>
                        <div class="input-block">
                            <label for="tel">Телефон</label>
                            <input
                                type="text"
                                name="tel"
                                v-mask="'+7 ### ### ## ##'"
                                placeholder="+7 000 000 00 00"
                                id="tel"
                                v-model.trim="phone"
                            />
                        </div>
                        <div class="valid" :style="`color:${color}`">
                            {{ valid }}
                        </div>
                        <div class="input-block-flex">
                            <button type="submit" class="btn-send" @click="validForm">Оставить заявку</button>
                            <p class="agree">
                                Нажимая на кнопку вы соглашаетесь с
                                <a href="/Политика_конфиденциальности.pdf" target="_blank"
                                    >политикой конфиденциальности</a
                                >
                            </p>
                        </div>
                    </form>
                </div>
                <div class="banquet-hall__photos">
                    <div class="banquet-hall__small">
                        <div class="photo">
                            <img src="../assets/images/banket-hall/1.jpg" alt="banket-hall" />
                        </div>
                        <div class="photo">
                            <img src="../assets/images/banket-hall/2.jpg" alt="banket-hall" />
                        </div>
                        <div class="photo">
                            <img src="../assets/images/banket-hall/3.jpg" alt="banket-hall" />
                        </div>
                        <div class="photo">
                            <img src="../assets/images/banket-hall/4.jpg" alt="banket-hall" />
                        </div>
                    </div>
                    <div class="banquet-hall__big">
                        <div class="photo">
                            <img src="../assets/images/banket-hall/5.jpg" alt="banket-hall" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "main-banquet-hall",
    data() {
        return {
            name: "",
            phone: "",
            valid: "",
            color: "red",
            title: `Банкетный зал для\nвашего торжества`,
        };
    },
    methods: {
        orderPost() {
            axios
                .post(
                    "https://admin.tcode.online/api/projects/zaman/site-requests/",
                    {
                        page: "Никах | Заман",
                        purpose: "Заявка на никах",
                        name: this.name,
                        phone: this.phone,
                        shop_id: 8,
                        shop_name: "Заман",
                    },
                    {
                        headers: {
                            // remove headers
                        },
                    }
                )
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },
        validForm() {
            this.color = "red";
            if (this.name === "") {
                this.valid = "Заполните поле Имя";
            } else if (this.phone === "") {
                this.valid = "Укажите телефон";
            } else if (this.name.length <= 1) {
                this.valid = "Слишком короткое имя";
            } else if (this.phone.length <= 15) {
                this.valid = "Телефон должен состоять из 11 цифр!";
            } else {
                this.orderPost();
                this.name = "";
                this.phone = "";
                this.color = "";
                this.valid = "Спасибо. Заявка успешно отправлена!";
            }
        },
    },
    mounted() {
        window.innerWidth > 767
            ? (this.title = `Банкетный зал для\nвашего торжества`)
            : (this.title = `Банкетный зал\nдля вашего торжества`);
    },
};
</script>

<style lang="scss">
.banquet-hall {
    background-color: #fff;
    padding-top: 80px;
    padding-bottom: 60px;
    .valid {
        position: relative;
        top: 1rem;
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
    @media (max-width: 991px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    @media (max-width: 767px) {
        padding-top: 35px;
        padding-bottom: 35px;
    }
    &__container {
        padding: 0 15px;
    }
    &__body {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__form,
    &__text {
        width: 50%;
        @media (max-width: 1440px) {
            width: 48%;
        }
        @media (max-width: 767px) {
            width: 100%;
        }
    }

    &__form {
        @media (max-width: 767px) {
            order: 1;
            margin-top: 10px;
        }
        @media (max-width: 480px) {
            margin-top: -50px;
        }
    }

    &__text {
        color: #373737;
    }

    &__title {
        font-family: "Century Gothic";
        font-size: clamp(18px, 5vw, 55px);
        line-height: clamp(26px, 5vw, 67.43px);
        font-weight: 400;
        white-space: pre;
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 18.72px;
        }
    }

    &__list {
        font-size: 22px;
        line-height: 24px;
        list-style: none;
        margin-top: 3rem;
        @media (max-width: 991px) {
            margin-top: 2rem;
            font-size: 16px;
        }
        @media (max-width: 767px) {
            font-size: 12px;
            line-height: 14px;
        }
        @media (max-width: 767px) {
            margin-top: 1.5rem;
            font-size: 10px;
        }
        li {
            margin-top: 1rem;
        }
    }

    &__photos {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        width: 100%;
        @media (max-width: 767px) {
            margin-top: 30px;
        }
    }
    &__small {
        width: 48%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (max-width: 480px) {
            height: 75%;
        }
        .photo {
            width: 48%;
            height: 395px;
            margin-bottom: 20px;
            @media (max-width: 1200px) {
                height: 300px;
            }
            @media (max-width: 991px) {
                height: 250px;
            }
            @media (max-width: 767px) {
                height: auto;
                margin-bottom: 5px;
            }
            @media (max-width: 480px) {
                height: 48%;
            }
        }
    }

    &__big {
        width: 50%;
        @media (max-width: 480px) {
            height: 75%;
        }
        .photo {
            margin-bottom: 15px;
            @media (max-width: 767px) {
                height: 98%;
                margin-bottom: 10px;
            }
        }
    }
    .input-block-flex {
        display: flex;
        align-items: center;
        margin-top: 35px;
        @media (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start;
        }
        @media (max-width: 767px) {
            margin-top: 25px;
        }
    }
    .input-block {
        @media (max-width: 767px) {
            margin-top: 10px;
        }
    }
    .input-block label,
    .input-block input {
        @media (max-width: 767px) {
            font-size: 12px;
            line-height: 35px;
        }
    }
    .input-block input {
        @media (max-width: 767px) {
            height: 32px;
            border-radius: 4px;
            padding: 0 10px;
        }
    }
    .input-block label {
        @media (max-width: 767px) {
            margin-bottom: 5px;
        }
    }
    .btn-send {
        min-width: 231px;
        height: 60px;
        border-radius: 40px;
        background: #d07f67;
        font-family: "Nunito Sans", sans-serif;
        color: #fff;
        border: none;
        cursor: pointer;
        line-height: clamp(13.64px, 5vw, 30.01px);
        font-size: clamp(10px, 5vw, 22px);
        &:hover,
        &:focus {
            background: #d06747;
            transition: 0.2s ease 0.2s;
        }
        @media (max-width: 767px) {
            min-width: 150px;
            height: 35px;
            border-radius: 20px;
            font-size: 14px;
            line-height: 20px;
        }
        @media (max-width: 480px) {
            min-width: 108px;
            height: 24px;
            font-size: 10px;
            line-height: 13.64px;
        }
    }
    .agree {
        color: #cacaca;
        line-height: 14px;
        font-size: 14px;
        margin-left: 20px;
        a {
            text-decoration: underline;
            color: inherit;
        }
        @media (max-width: 991px) {
            margin-top: 20px;
            margin-left: 0;
        }
        @media (max-width: 767px) {
            font-size: 12px;
        }
        @media (max-width: 480px) {
            font-size: 10px;
        }
    }
    .photo {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 20px;
            @media (max-width: 767px) {
                border-radius: 10px;
            }
            @media (max-width: 480px) {
                border-radius: 5px;
            }
        }
    }
}
.ai {
    position: relative;
    overflow: hidden;
    padding: 0 0 50% 0;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>
