<template>
    <div class="popupForm">
        <div class="popupForm__container" @click.stop>
            <div class="popupForm__close" @click="$emit('closePopupFeedback')">
                <img src="@/assets/images/svg/close_popup.svg" alt="close" />
            </div>
            <h2 class="popupForm__fanks" v-if="isFanks">{{ fanks }}</h2>
            <div class="popupForm__body" v-else>
                <h1 class="popupForm__title">{{ title }}</h1>
                <form @submit.prevent="validForm" class="form">
                    <div class="popupForm__stars">
                        <input type="hidden" name="rating" v-model="rating" />
                        <div
                            class="star"
                            v-for="(star, index) in starLimit"
                            :key="index"
                            @click="setRatingStar(star)"
                            :class="rating >= star ? 'star-active' : ''"
                        ></div>
                    </div>
                    <div class="inputs">
                        <div class="input-block">
                            <textarea
                                name="comment"
                                type="text"
                                id="comment"
                                placeholder="Напишите свой отзыв"
                                v-model="comment"
                            ></textarea>
                        </div>
                        <div class="input-block">
                            <input type="text" name="name" id="name" placeholder="Ваше имя" v-model="name" />
                        </div>
                        <div class="input-block">
                            <input
                                type="text"
                                name="tel"
                                id="tel"
                                placeholder="+7 900 000 00 00"
                                v-mask="'+7 ### ### ## ##'"
                                v-model.trim="phone"
                            />
                        </div>
                    </div>
                    <div class="error">{{ errorText }}</div>
                    <div class="agree">
                        Указывая телефон, вы принимаете
                        <a href="/Политика_конфиденциальности.pdf">Политику обработки персональных данных</a>
                    </div>
                    <div class="form__next">
                        <button
                            type="submit"
                            name="continue"
                            :class="comment.length > 3 && phone.length > 10 && rating ? `` : `_disable`"
                        >
                            Отправить
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: "FeedbackPopup",
    data() {
        return {
            errorText: "",
            reservationId: "",
            name: "",
            phone: "",
            comment: "",
            title: "Оставьте ваш отзыв",
            isFanks: false,
            fanks: `Спасибо\n за ваш отзыв!`,
            rating: 0,
            starLimit: 5,
        };
    },
    methods: {
        setRatingStar(star) {
            console.log(star);
            this.rating = star;
        },
        validForm() {
            if (this.rating === 0) {
                this.errorText = "Поставьте оценку";
            } else if (this.comment === "") {
                this.errorText = "Напишите свой отзыв";
            } else if (this.name === "") {
                this.errorText = "Напишите имя";
            } else if (this.phone === "") {
                this.errorText = "Укажите телефон";
            } else if (this.comment.length <= 3) {
                this.errorText = "Слишком короткий отзыв";
            } else if (this.phone.length <= 10) {
                this.errorText = "Телефон должен состоять из 11 цифр!";
            } else {
                axios
                    .post(
                        "https://admin.tcode.online/api/projects/tatar/site-requests/",
                        {
                            purpose: "Отзыв с сайта",
                            name: this.name,
                            comment: this.comment,
                            phone: this.phone,
                            rating: this.rating,
                            shop_id: 8,
                            shop_name: "zaman",
                        },
                        {
                            headers: {
                                // remove headers
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response);
                        this.reservationId = response.data.id;
                        this.phone.length > 15 ? this.phone.length === 15 : 0;
                        this.errorText = "";
                        this.name = "";
                        this.phone = "";
                        this.comment = "";
                        this.isFanks = true;
                    })
                    .catch((error) => {
                        console.log(error.response);
                    });
            }
        },
    },
};
</script>

<style lang="scss">
.popupForm {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    @media (max-width: 767px) {
        align-items: flex-end;
        width: 100%;
    }
    ._disable {
        background-color: #f3f3f3 !important;
        color: #b0b0b0 !important;
    }
    &__body {
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    &__container {
        background-color: #fff;
        width: 100%;
        max-width: 512px;
        border-radius: 20px;
        position: relative;
        padding: 50px 40px 40px;
        opacity: 0;
        bottom: -100%;
        animation: showBottom 0.2s forwards 0.2s;
        @keyframes showBottom {
            0% {
                opacity: 0;
                bottom: -100%;
            }
            100% {
                opacity: 1;
                bottom: 0;
            }
        }
        @media (max-width: 767px) {
            max-width: 100%;
            padding: 50px 20px 20px;
            border-radius: 10px 10px 0 0;
        }
    }
    &__close,
    &__back {
        position: absolute;
        top: 20px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__back {
        width: 20px;
        height: 20px;
    }
    &__close {
        width: 20px;
        height: 20px;
        right: 20px;
        cursor: pointer;
        &:hover,
        &:focus {
            filter: invert(0.5);
            transition: 0.2s ease 0.2s;
        }
    }
    &__back {
        left: 20px;
        width: 10px;
    }
    &__title {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: #2a374b;
        text-align: center;
        white-space: pre-line;
        @media (max-width: 767px) {
            font-size: 18px;
        }
        @media (max-width: 479px) {
            font-size: 16px;
        }
    }
    &__subtitle {
        font-size: 14px;
        line-height: 24px;
        color: #0d0d0d;
        text-align: center;
        white-space: pre;
        margin-top: 2rem;
    }
    &-block {
        height: 50vh;
        font-size: 22px;
    }
    &-select {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        h2 {
            font-size: 16px;
            font-weight: 500;
            color: #0d0d0d;
            line-height: 24px;
        }
        select:invalid {
            color: #9c9c9c;
        }
        select[disabled] {
            color: #9c9c9c;
        }
    }
    &__srt {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #0d0d0d;
    }
    &__ornament {
        width: 62px;
        height: 12px;
        margin: 35px auto;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: invert(0.5);
        }
    }
    &__message {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #5a5a5a;
        white-space: pre;
        @media (max-width: 767px) {
            justify-content: center;
        }
    }
    input,
    select {
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: #f7f7f7;
        background-image: url("~@/assets/images/svg/select-arrow.svg");
        background-repeat: no-repeat;
        background-position: 95%;
        cursor: pointer;
        height: 56px;
        border-radius: 10px;
        border: none;
        padding: 0 1rem;
        border: none;
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        color: #2a374b;
        font-family: "Nunito", sans-serif;
        outline: none;
    }
    input[type="text"] {
        background-image: none;
    }
    .select-time {
        width: 50%;
        @media (max-width: 767px) {
            width: 100%;
            margin-top: 1rem;
        }
    }
    .form {
        width: 100%;
        display: flex;
        flex-direction: column;
        & .dp__input_wrap {
            width: 90%;
            @media (max-width: 767px) {
                width: 100%;
            }
        }
        & .dp__input {
            width: 100%;
            padding: 0 1rem;
            font-size: 16px;
            line-height: 28px;
            font-weight: 600;
            color: #2a374b;
        }
        & .dp__icon,
        & .dp__clear_icon {
            display: none;
        }
        &__visit {
            margin-top: 1rem !important;
            margin-bottom: 1rem;
            @media (max-width: 767px) {
                .form__flex {
                    flex-direction: column;
                }
            }
        }
        &__names {
            .form__flex {
                .input-block {
                    width: 48%;
                }
            }
        }
        &__flex {
            display: flex;
            justify-content: space-between;
        }
        &__select-table {
            label {
                margin-bottom: 1rem !important;
            }
            .form__flex {
                flex-wrap: wrap;
                gap: 8px;
                @media (max-width: 1199px) {
                    justify-content: flex-start;
                }
                @media (max-width: 991px) {
                    justify-content: center;
                }
            }
        }
        &__agree {
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            color: #373737;
            margin-top: 2rem;
            @media (max-width: 991px) {
                margin-top: 0;
            }
            @media (max-width: 767px) {
                font-size: 14px;
            }
            input {
                height: 25px;
            }
        }
        &__check {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
        &__check + label {
            display: flex;
            user-select: none;
            cursor: pointer;
            &::before {
                content: "";
                width: 20px;
                height: 20px;
                box-sizing: border-box;
                cursor: pointer;
                margin-right: 15px;
                background: #f7f7f7;
            }
            a {
                color: #373737;
                margin-left: 5px;
                &:hover,
                &:focus {
                    color: #7c7f7e;
                    transition: 0.2s ease;
                }
            }
        }
        &__check:checked + label::before {
            background: #373737;
            background-repeat: no-repeat;
            background-position: center;
            border: 5px solid #f7f7f7;
        }
        &__next {
            display: flex;
            justify-content: center;
            margin-top: 2rem;
            button {
                background-color: #d07f67;
                color: #fff;
                width: 240px;
                height: 56px;
                border-radius: 20px;
                font-size: 16px;
                font-weight: 600;
                line-height: 28px;
                text-align: center;
                font-family: "Nunito", sans-serif;
                border: none;
                &:hover,
                &:focus {
                    background-color: #a06d2e;
                    transition: 0.2s ease 0.2s;
                }
            }
        }
    }
    .select-item {
        display: inline-block;
        width: 22%;
        margin-top: 1rem;
        @media (max-width: 1199px) {
            width: 25%;
            margin-right: 1rem;
        }
        @media (max-width: 991px) {
            width: 47%;
        }
        @media (max-width: 767px) {
            width: 100%;
            height: 45px;
        }
        &:last-child,
        label {
            @media (max-width: 991px) {
                width: 100% !important;
            }
        }
    }
    .select-item input[type="radio"] {
        display: none;
    }
    .select-item label {
        display: inline-block;
        cursor: pointer;
        height: 60.75px;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        user-select: none;
        display: flex;
        justify-content: center;
        text-align: center;
        padding: 10px;
        font-weight: 400 !important;
        font-size: clamp(10px, 5vw, 20px);
        line-height: 35px;
        color: #7c7f7e !important;
        @media (max-width: 767px) {
            font-size: 14px;
            background: #f7f7f7;
            border: 1px solid #f7f7f7;
            height: auto;
        }
    }
    .select-item:not(:last-child) label {
        width: 140px;
        @media (max-width: 992px) {
            width: 100%;
        }
    }
    .select-item:last-child {
        label {
            width: 300px;
            @media (max-width: 991px) {
                width: 49%;
            }
            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }
    label[for="count5"] {
        width: 300px;
        @media (max-width: 991px) {
            width: 49%;
        }
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    /* Checked */
    .select-item input[type="radio"]:checked + label {
        color: #373737 !important;
        border: 1px solid #2e3c51;
    }
    /* Hover */
    .select-item label:hover {
        color: #373737;
        border: 1px solid #2e3c51;
        transition: 0.2s ease;
    }
    .range {
        position: relative;
        width: 100%;
        max-width: 400px;
        height: 10px;
        margin-top: 1.5rem;
        overflow: hidden;
        border-radius: 5px;
        @media (max-width: 767px) {
            max-width: unset;
            height: 20px;
        }
        input[name="range"] {
            position: absolute;
            top: 0;
            pointer-events: none;
        }
        .range-width {
            height: 10px;
            background: #d07f67;
            border-radius: 10px;
            position: relative;
            z-index: 1;
            @media (max-width: 767px) {
                height: 20px;
            }
        }
    }
    .inputs {
        .input-block {
            margin-top: 1rem;
            input {
                &::placeholder {
                    color: #b0b0b0;
                }
            }
            textarea {
                height: 120px;
                padding-top: 10px;
                &::placeholder {
                    color: #b0b0b0;
                }
            }
            input,
            textarea {
                font-size: 14px;
                line-height: 24px;
                background: none;
                color: #0d0d0d;
                border: 1px solid #b0b0b0;
                border-radius: 20px;
                &::placeholder {
                    font-family: "Nunito", sans-serif;
                }
            }
            .flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
    .agree {
        font-size: 8px;
        line-height: 14px;
        color: #b0b0b0;
        margin-bottom: 1rem;
        a {
            text-decoration: underline;
            color: inherit;
            &:hover,
            &:focus {
                text-decoration: none;
                transition: 0.2s ease 0.2s;
            }
        }
    }
    .error {
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: center;
        position: relative;
        top: 1rem;
        color: red;
        height: 1rem;
    }
    &__stars {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &__fanks {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: #2a374b;
        text-align: center;
        white-space: pre;
        padding: 20px 20px 30px;
    }
    .agree {
        text-align: center;
    }
    .form__next {
        margin-top: 10px;
    }
    .error {
        top: 2rem;
    }
    .star {
        width: 32px;
        height: 32px;
        background-repeat: no-repeat;
        margin: 0 5px;
        background-image: url("~@/assets/images/svg/star-default.svg");
        cursor: pointer;
    }
    .star-active {
        background-image: url("~@/assets/images/svg/star-active.svg");
        transition: all ease 0.2s;
    }
}
</style>
