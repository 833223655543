<template>
    <section class="photos-rest" id="photos">
        <div class="photos-rest__body center-wrapper">
            <h3 class="photos-rest__title">Фото ресторана</h3>
            <div class="photos-rest__photos">
                <div class="photos-rest__photo-big">
                    <div class="photos-rest__photo">
                        <img src="../assets/images/photos-rest/1.jpg" alt="photos" />
                    </div>
                </div>
                <div class="photos-rest__photo-small">
                    <div class="photos-rest__photo">
                        <img src="../assets/images/photos-rest/2.jpg" alt="photos" />
                    </div>
                    <div class="photos-rest__photo">
                        <img src="../assets/images/photos-rest/3.jpg" alt="photos" />
                    </div>
                    <div class="photos-rest__photo">
                        <img src="../assets/images/photos-rest/4.jpg" alt="photos" />
                    </div>
                    <div class="photos-rest__photo">
                        <img src="../assets/images/photos-rest/5.jpg" alt="photos" />
                    </div>
                </div>
            </div>
            <h3 class="photos-rest__title flex">
                Как нас найти
                <!-- <a href="#"
          ><span class="photos-rest__play"
            ><img src="../assets/images/play.svg" alt="play"
          /></span>
          <span class="photos-rest__link">Видео подсказка</span></a
        > -->
            </h3>
            <div class="photos-rest__steps">
                <div class="photos-rest__step">
                    <div class="photos-rest__number">1</div>
                    <div class="photos-rest__desc">
                        Зайдите в БЦ "Территория 3000",<br />
                        вход "Темная сторона" по адресу ул.Генерала Горбатова 2
                    </div>
                </div>
                <div class="photos-rest__step">
                    <div class="photos-rest__number">2</div>
                    <div class="photos-rest__desc">Поверните направо (к лифтам) и поднимитесь на 8 этаж ТЦ</div>
                </div>
                <div class="photos-rest__step">
                    <div class="photos-rest__number">3</div>
                    <div class="photos-rest__desc">Выходя из лифта поверните налево, на нашу террасу</div>
                </div>
            </div>
            <div id="contacts">
                <h3 class="photos-rest__title">Контакты</h3>
                <div class="photos-rest__block-cols">
                    <div class="photos-rest__contacts">
                        <div class="photos-rest__contacts-col">
                            <div class="photos-rest__contacts-row">
                                <p>Связаться</p>
                                <a
                                    href="tel:+79273546263"
                                    class="photos-rest__contacts-tel"
                                    onclick="ym(95816218,'reachGoal','phone'); return true;"
                                    >+7 927 354 62 63</a
                                >
                            </div>
                            <div class="photos-rest__contacts-row">
                                <p>График работы</p>
                                <p>ПН-ЧТ: 11:00 - 22:00</p>
                                <p>ПТ-СБ: 11:00 - 23:00</p>
                                <p>ВС: 11:00 - 22:00</p>
                            </div>
                        </div>
                        <div class="photos-rest__contacts-col">
                            <div class="photos-rest__contacts-row">
                                <p>Написать</p>
                                <a href="mailto:zaman.tbt@gmail.com" class="photos-rest__contacts-mail"
                                    >zaman.tbt@gmail.com</a
                                >
                            </div>
                            <div class="photos-rest__contacts-row">
                                <p>Мы в соц сетях</p>
                                <div class="photos-rest__socials">
                                    <a href="#" class="photos-rest__social">
                                        <img src="../assets/images/Telegram.svg" alt="Telegram" />
                                    </a>
                                    <a href="https://vk.com/wall-221676072_31" class="photos-rest__social">
                                        <img src="../assets/images/VK.svg" alt="VK" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="photos-rest__map">
                        <iframe
                            src="https://yandex.ru/map-widget/v1/?um=constructor%3A89f8970566104dc7b8d967ff7d8ce27de86e12c77e2b0923eb242f96fb2b5d8e&amp;source=constructor"
                            width="100%"
                            height="400"
                            frameborder="0"
                        ></iframe>
                    </div>
                </div>
                <button class="photos-rest__button btn" @click="openPopupFeedback">Оставить отзыв</button>
            </div>
        </div>
    </section>
    <feedback-popup
        v-if="isPopupFeedback"
        @click="isPopupFeedback = false"
        @close-popup-feedback="closePopupFeedback"
    />
</template>

<script>
import FeedbackPopup from "./FeedbackPopup.vue";
export default {
    components: { FeedbackPopup },
    name: "main-page-photos",
    data() {
        return {
            isPopupFeedback: false,
        };
    },
    methods: {
        openPopupFeedback() {
            this.isPopupFeedback = true;
        },
        closePopupFeedback() {
            this.isPopupFeedback = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.photos-rest {
    background-color: #f8f4eb;
    padding: 50px 0;
    &__button {
        margin-top: 40px;
    }
    &__body {
        padding: 0 15px;

        @media screen and (min-width: 1440px) {
            padding: 0 58px;
        }
    }
    &__title {
        font-family: "Tenor Sans", sans-serif;
        color: #373737;
        margin-bottom: 3rem;
        line-height: clamp(12px, 5vw, 46.8px);
        font-size: clamp(16px, 5vw, 40px);
        a {
            font-family: "Nunito", sans-serif;
            color: #373737;
            text-decoration: none;
            display: flex;
            line-height: 30px;
            font-size: clamp(12px, 5vw, 22px);
            &:hover,
            &:focus {
                opacity: 0.5;
            }
            @media (max-width: 1439px) {
                margin-top: 1rem;
            }
        }
        ~ .flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 1439px) {
                flex-direction: column;
            }
        }
    }
    &__play {
        width: 29px;
        height: 29px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }
    &__link {
        display: block;
        position: relative;
        width: fit-content;
        &::after {
            content: "";
            border-bottom: 1px solid #373737;
            bottom: 4px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }
    &__photos {
        display: flex;
        padding-bottom: 3rem;
        @media (max-width: 767px) {
            flex-direction: column;
        }
    }
    &__photo {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 20px;
            overflow: hidden;
        }
    }
    &__photo-big {
        margin-right: 2rem;
        display: flex;
    }
    &__photo-big,
    &__photo-small {
        width: 50%;
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    &__photo-small {
        display: flex;
        flex-wrap: wrap;
        margin: -0.5rem -0.75rem -0.5rem -1rem;
        @media (max-width: 767px) {
            margin: 1rem 0 0;
        }
        .photos-rest__photo {
            flex: 0 0 50%;
            padding: 0.5rem 0.75rem;
            @media (max-width: 767px) {
                &:nth-child(odd) {
                    padding: 0px 0.25rem 0.5rem 0px;
                }
                &:nth-child(even) {
                    padding: 0px 0px 0.5rem 0.25rem;
                }
            }
        }
    }
    &__steps {
        display: flex;
        margin: 0 -1rem;
        padding-bottom: 3rem;
        @media (max-width: 1439px) {
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 auto;
            padding-bottom: 2rem;
        }
        @media (max-width: 767px) {
            flex-direction: column;
        }
    }
    &__step {
        background: #fefefe;
        padding: 1rem 1rem 2.5rem;
        border-radius: 1rem;
        margin: 0 1rem;
        @media (max-width: 1439px) {
            flex: 0 0 48%;
            width: 48%;
            margin: 0px 0px 1rem 0px;
        }
        @media (max-width: 767px) {
            flex: 1 1 auto;
            width: 100%;
        }
    }
    &__number {
        border: 2px solid #2a374b;
        border-radius: 50%;
        font-family: "Nunito", sans-serif;
        font-weight: 600;
        line-height: 22px;
        color: #2a374b;
        display: flex;
        justify-content: center;
        align-items: center;
        width: clamp(22px, 5vw, 50px);
        height: clamp(22px, 5vw, 50px);
        font-size: clamp(12px, 5vw, 22px);
    }
    &__desc {
        font-family: "Nunito", sans-serif;
        color: #373737;
        margin-top: 1rem;
        padding-right: 2rem;
        font-size: clamp(10px, 5vw, 22px);
        line-height: clamp(16px, 5vw, 24px);
        @media (max-width: 767px) {
            br {
                display: none;
            }
        }
    }
    &__block-cols {
        display: flex;
        @media (max-width: 1439px) {
            flex-direction: column;
        }
    }
    &__contacts {
        background-color: #fefefe;
        border-radius: 1rem;
        width: 50%;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        margin-right: 1.5rem;
        @media (max-width: 1439px) {
            width: 100%;
            margin-right: 0;
            padding: 0 1rem 1rem;
        }
        @media (max-width: 600px) {
            flex-direction: column;
        }
    }
    &__contacts-col {
        display: flex;
        flex-direction: column;
        margin-right: 1.5rem;
        @media (max-width: 1439px) {
            margin-right: 0;
            margin-top: 1rem;
            justify-content: space-between;
        }
        p {
            font-family: "Nunito", sans-serif;
            line-height: 30px;
            color: #373737;
            font-size: clamp(10px, 5vw, 22px);
        }
    }
    &__contacts-tel,
    &__contacts-mail {
        font-family: "Nunito", sans-serif;
        line-height: 30px;
        color: #2a374b;
        width: 252px;
        height: 50px;
        border-radius: 20px;
        border: 1px solid #2a374b;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: clamp(12px, 5vw, 26px);
        &:hover,
        &:focus {
            background: #2a374b;
            color: #fff;
            transition: 0.2s ease 0.2s;
        }
        @media (max-width: 1439px) {
            height: 32px;
        }
    }
    &__contacts-tel {
        width: 252px;
        @media (max-width: 1439px) {
            width: fit-content;
            padding: 0 10px;
        }
    }
    &__contacts-mail {
        width: 307px;
        @media (max-width: 1439px) {
            width: fit-content;
            padding: 0 10px;
        }
    }
    &__socials {
        display: flex;
        align-items: center;
    }
    &__social {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid #2a374b;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        &:hover,
        &:focus {
            background: #2a374b;
            transition: 0.2s ease 0.2s;
            img {
                filter: invert(0.5);
                transition: 0.2s ease 0.2s;
            }
        }
        @media (max-width: 1439px) {
            width: 24px;
            height: 24px;
            img {
                width: 12px;
            }
        }
    }
    &__map {
        width: 50%;
        border-radius: 1rem;
        overflow: hidden;
        @media (max-width: 1439px) {
            width: 100%;
            margin-top: 1rem;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__contacts-row {
        p,
        a {
            margin-top: 1rem;
        }
    }
}
#map {
    width: 100%;
    height: 297px;
    @media (max-width: 767px) {
        height: 160px;
    }
}
</style>
